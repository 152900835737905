<template>
    <div class="experiment_matching" v-loading="isLoading">
        <div class="header_wrapper">
            <div class="header_left">
                <div class="input_item">
                    <span>配合比编号：</span>
                    <el-input
                        v-model="theoryFormula.theory_formula_number"
                        placeholder="自动生成"
                        disabled
                    ></el-input>
                </div>
                <div class="input_item">
                    <span class="mustFill">配合比名称：</span>
                    <el-input v-model="theoryFormula.theory_formula_name" placeholder="请输入内容"></el-input>
                </div>
                <div class="input_item">
                    <span class="mustFill">配方类型：</span>
                    <el-select v-model="theoryFormula.formula_type" placeholder="请选择" @change="formulaTypeChange">
                        <el-option
                            v-for="item in formula_typeData"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
            </div>
            <div class="header_right">
                <el-button type="primary" @click="theoryFormulaSave">
                    保存
                </el-button>
            </div>
        </div>
        <div class="main_wrapper">
            <div class="main_top">
                <div class="product_marking_wrapper">
                    <p>产品标示</p>
                    <ul>
                        <li>
                            <span>强度等级：</span>
                            <el-select
                                v-model="theoryFormula.strength_grade"
                                placeholder="请选择"
                                @change="selectGet('strength_grade')"
                            >
                                <el-option
                                    v-for="item in ProductIdentification.qddj.options"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                ></el-option>
                            </el-select>
                        </li>
                        <li>
                            <span>抗渗等级：</span>
                            <el-select
                                v-model="theoryFormula.impervious"
                                placeholder="请选择"
                                @change="selectGet('impervious')"
                            >
                                <el-option
                                    v-for="item in ProductIdentification.ksdj.options"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                ></el-option>
                            </el-select>
                        </li>
                        <li>
                            <span>抗冻等级：</span>
                            <el-select
                                v-model="theoryFormula.antifreeze"
                                placeholder="请选择"
                                @change="selectGet('antifreeze')"
                            >
                                <el-option
                                    v-for="item in ProductIdentification.kddj.options"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                ></el-option>
                            </el-select>
                        </li>
                        <li>
                            <span>抗折要求：</span>
                            <el-select
                                v-model="theoryFormula.flexural"
                                placeholder="请选择"
                                @change="selectGet('flexural')"
                            >
                                <el-option
                                    v-for="item in ProductIdentification.kzyq.options"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                ></el-option>
                            </el-select>
                        </li>
                        <li>
                            <span>特殊要求：</span>
                            <el-select
                                v-model="theoryFormula.special_require"
                                placeholder="请选择"
                                @change="selectGet('special_require')"
                            >
                                <el-option
                                    v-for="item in ProductIdentification.tsyq.options"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                ></el-option>
                            </el-select>
                        </li>
                    </ul>
                </div>
                <div class="basic_info_wrapper">
                    <p>基本信息</p>
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <div class="grid-content bg-purple">
                                <ul>
                                    <li>
                                        <span class="item_title">设计容重(kg)：</span>
                                        <el-input v-model="theoryFormula.design_volume_weight" placeholder="请输入内容" disabled></el-input>
                                    </li>
                                    <li>
                                        <span class="item_title">水胶比(%)：</span>
                                        <el-input v-model="theoryFormula.water_binder_ratio" placeholder="请输入内容"></el-input>
                                    </li>
                                    <li>
                                        <span class="item_title">最大粒径：</span>
                                        <el-input v-model="theoryFormula.max_particle_size" placeholder="请输入内容"></el-input>
                                    </li>
                                    <li>
                                        <span class="item_title">施工季节：</span>
                                        <el-select v-model="theoryFormula.season" placeholder="请选择">
                                            <el-option
                                                v-for="item in seasonData"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                    </li>
                                    <li>
                                        <span class="item_title">浇捣方式：</span>
                                        <el-select v-model="theoryFormula.poured" placeholder="请选择">
                                            <el-option
                                                v-for="item in pouredData"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                    </li>
                                    <li>
                                        <span class="item_title">技术要求：</span>
                                        <el-input v-model="theoryFormula.specification" placeholder="请输入内容"></el-input>
                                    </li>
                                </ul>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="grid-content bg-purple-light">
                                <ul>
                                    <li>
                                        <span class="item_title">坍落度(mm)：</span>
                                        <el-input v-model="theoryFormula.slump" style="width:1.15rem"></el-input>
                                        <i>±</i>
                                        <el-input v-model="theoryFormula.slump_flaot" style="width:1.2rem"></el-input>
                                    </li>
                                    <li>
                                        <span class="item_title">砂率(%)：</span>
                                        <el-input v-model="theoryFormula.sand_rate" placeholder="请输入内容"></el-input>
                                    </li>
                                    <li>
                                        <span class="item_title">水灰比：</span>
                                        <el-input v-model="theoryFormula.water_cement_ratio" placeholder="请输入内容"></el-input>
                                    </li>
                                    <li>
                                        <span class="item_title">细度模数：</span>
                                        <el-input v-model="theoryFormula.fineness_modulus" placeholder="请输入内容"></el-input>
                                    </li>
                                    <li>
                                        <span class="item_title">搅拌时间(s):</span>
                                        <el-input v-model="theoryFormula.mixing_time" placeholder="请输入内容"></el-input>
                                    </li>
                                    <li>
                                        <span class="item_title">养护方式：</span>
                                        <el-select v-model="theoryFormula.curing" placeholder="请选择">
                                            <el-option
                                                v-for="item in curingData"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                    </li>
                                </ul>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="main_bottom">
                <div class="main_header">
                    <div class="btn_wrapper">
                        <el-button
                            type="primary"
                            @click="addChange"
                        >
                            新增
                        </el-button>
                    </div>
                </div>
                <div class="main_content">
                    <div class="content_left">
                        <el-table
                            :data="tableData"
                            border
                            :header-cell-style="{ height: '0.34rem', background: 'rgba(237,240,245,1)', color: '#022782', padding: '0'}"
                            height="2.8rem"
                        >
                            <el-table-column label="原材料分类">
                                <template slot-scope="scope">
                                    <el-select
                                        v-model="scope.row.stock_category"
                                        placeholder="请选择"
                                        @change="stockCategoryChange(scope.row.stock_category,scope.$index)"
                                    >
                                        <el-option
                                            v-for="(item, index) in stockCategoryData"
                                            :key="index"
                                            :label="item.name"
                                            :value="item.code"
                                        >
                                        </el-option>
                                    </el-select>
                                    <input type="hidden" name="" v-model="scope.row.stock_category_name">
                                    <input type="hidden" name="" v-model="scope.row.stock_category_dictcode">
                                </template>
                            </el-table-column>
                            <el-table-column label="原材料子类">
                                <template slot-scope="scope">
                                    <el-select
                                        v-model="scope.row.material_child_code"
                                        placeholder="请选择"
                                        @change="materialChildChange(scope.row.stock_category,scope.row.material_child_code,scope.$index)"
                                    >
                                        <el-option
                                            v-for="(item, index) in scope.row.materialChildData"
                                            :key="index"
                                            :label="item.name"
                                            :value="item.code"
                                        >
                                        </el-option>
                                    </el-select>
                                    <input type="hidden" name="" v-model="scope.row.material_child_name">
                                    <input type="hidden" name="" v-model="scope.row.material_child_dictcode">
                                </template>
                            </el-table-column>
                            <el-table-column label="原材料规格">
                                <template slot-scope="scope">
                                    <el-select
                                        v-model="scope.row.material_code"
                                        placeholder="请选择"
                                        @change="materialChange(scope.row.material_code,scope.$index)"
                                    >
                                        <el-option
                                            v-for="(item, index) in scope.row.materialData"
                                            :key="index"
                                            :label="item.name"
                                            :value="(scope.row.stock_category_dictcode === '203' || scope.row.stock_category_dictcode === '204')
                                                ? item.dic_code : item.code"
                                        >
                                        </el-option>
                                    </el-select>
                                    <input type="hidden" name="" v-model="scope.row.material_name">
                                </template>
                            </el-table-column>
                            <el-table-column label="标准用量">
                                <template slot-scope="scope">
                                    <el-input
                                        v-model="scope.row.theory_quantity"
                                        @input="experimentQuantityChange"
                                        @mousewheel.native.prevent
                                        placeholder="请输入"
                                        min="0"
                                        type="number"
                                    >
                                    </el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="含水率">
                                <template slot-scope="scope">
                                    <el-input
                                        v-model="scope.row.moisture_ratio"
                                        @mousewheel.native.prevent
                                        min="0"
                                        type="number"
                                        placeholder="请输入"
                                    ></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="差值">
                                <template slot-scope="scope">
                                    <el-input
                                        v-model="scope.row.moisture_ratio_difference"
                                        @mousewheel.native.prevent
                                        min="0"
                                        type="number"
                                        placeholder="请输入"
                                    ></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="含石率">
                                <template slot-scope="scope">
                                    <el-input
                                        v-model="scope.row.stone_rate"
                                        @mousewheel.native.prevent
                                        min="0"
                                        type="number"
                                        placeholder="请输入"
                                    ></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="差值">
                                <template slot-scope="scope">
                                    <el-input
                                        v-model="scope.row.stone_rate_difference"
                                        @mousewheel.native.prevent
                                        min="0"
                                        type="number"
                                        placeholder="请输入"
                                    ></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        size="mini"
                                        @click="deleteChange(scope.row,scope.$index)"
                                        class="el-icon-delete"
                                        style="color:red"
                                    >
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="content_rigth">
                        <ul>
                            <li class="sp1">
                                <span style="text-align: center">设计容重</span>
                                <p>
                                    {{ theoryFormula.design_volume_weight || 0 }}
                                    <i>kg</i>
                                </p>
                            </li>
                            <li class="sp2">
                                <span style="text-align: center">坍落度</span>
                                <p>
                                    {{ slumpNumber }}
                                    <i>mm</i>
                                </p>
                            </li>
                            <li class="sp3">
                                <span style="text-align: center">水胶比</span>
                                <p>
                                    {{ theoryFormula.water_binder_ratio || 0 }}
                                    <i>%</i>
                                </p>
                            </li>
                            <li class="sp4">
                                <span style="text-align: center">砂率</span>
                                <p>
                                    {{ theoryFormula.sand_rate || 0 }}
                                    <i>%</i>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        extr: {
            type: [Array, Object, String],
        },
    },
    computed: {
        slumpNumber() {
            if (this.theoryFormula.slump && this.theoryFormula.slump_flaot) {
                return this.theoryFormula.slump + '±' + this.theoryFormula.slump_flaot;
            }
            return '0';
        },
    },
    data() {
        return {
            // 产品标识数据
            ProductIdentification: {
                // 强度等级
                qddj: {
                    checked: false,
                    options: [],
                },
                // 抗渗等级
                ksdj: {
                    checked: false,
                    options: [],
                },
                // 抗冻等级
                kddj: {
                    checked: false,
                    options: [],
                },
                // 抗折要求
                kzyq: {
                    checked: false,
                    options: [],
                },
                // 特殊要求
                tsyq: {
                    checked: false,
                    options: [],
                },
            },
            // 配方类型
            formula_typeData: [
                {
                    label: '混凝土',
                    value: 0,
                },
                {
                    label: '砂浆',
                    value: 1,
                },
                {
                    label: '润泵砂浆',
                    value: 3,
                },
            ],
            // 施工季节下拉数据
            seasonData: [
                {
                    label: '春',
                    value: 1,
                },
                {
                    label: '夏',
                    value: 2,
                },
                {
                    label: '秋',
                    value: 3,
                },
                {
                    label: '冬',
                    value: 4,
                },
            ],
            // 浇捣方式下拉数据
            pouredData: [
                {
                    label: '机械',
                    value: 1,
                },
            ],
            // 养护方式下拉数据
            curingData: [
                {
                    label: '标养',
                    value: 1,
                },
            ],
            // 保存理论配比基本信息
            theoryFormula: {
                theory_formula_number: '',
                formula_type: 0, // 配方类型
                poured: 1, // 浇捣方式
                curing: 1, // 养护方式
                slump: 0, // 坍落度
                slump_flaot: 0,
                design_volume_weight: 0, // 设计容重
                water_binder_ratio: 0, // 水胶比
                water_cement_ratio: 0, // 水灰比
                max_particle_size: 0, // 最大粒径
                sand_rate: 0, // 砂率
                fineness_modulus: 0, // 细度模数
            },
            tableData: [],
            stockCategoryData: [], // 原材料名称select数据
            sort_num: 0,
            isLoading: false,
        };
    },
    created() {

    },
    mounted() {
        // 获取产品标识下面select的数据
        this.selectAll();

        // 获取原材料名称数据
        this.getStockCategory();

        // 是否获取详情，编辑获取，新增不获取
        if (this.extr && this.extr.rowData) {
            // 初始化页面数据
            this.getInitData(this.extr.rowData.theory_formula_number);
        }

    },
    methods: {
        // 编辑时获取保存的详情
        getInitData(theory_formula_number) {
            this.$axios
                .get('/interfaceApi/production/theoryformula/formulawithentity/' + theory_formula_number)
                .then(res => {
                    this.theoryFormula = res.theoryFormula;
                    this.tableData = res.theoryFormulaEntity;
                    this.tableData.forEach((item, index) => {
                        // 根据原材料分类code过滤出父对象
                        const stockCategoryRow = this.stockCategoryData.filter(j => {
                            return j.code === item.stock_category;
                        });

                        if (stockCategoryRow.length > 0) {
                            if (stockCategoryRow[0].dic_code === '203') { // 细骨料
                                this.getMaterialChild('F3-02', 1, index);
                                this.getMaterial(item.material_child_code, index, 1);
                            } else if (stockCategoryRow[0].dic_code === '204') { // 粗骨料
                                this.getMaterialChild('F3-03', 1, index);
                                this.getMaterial(item.material_child_code, index, 1);
                            } else if (stockCategoryRow[0].dic_code.indexOf('-') > -1) {
                                this.$set(this.tableData[index], 'materialChildData', stockCategoryRow);
                                this.getMaterial( stockCategoryRow[0].dic_code, index);
                            } else {
                                this.getMaterialChild(stockCategoryRow[0].dic_code, '', index);
                                this.getMaterial( item.material_child_code, index);
                            }

                        }
                    });
                    if (this.theoryFormula.formula_type === 1) {
                        // 获取强度等级下拉列表的数据
                        this.SelectData('005-SJ0', '0', 'qddj');
                    } else {
                        // 获取强度等级下拉列表的数据
                        this.SelectData('004-PH0', '0', 'qddj');
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存实验配比基本信息
        theoryFormulaSave() {
            this.tableData.forEach(item => {
                delete item.materialChildData;
                delete item.materialData;
            });
            this.isLoading = true;
            this.$axios
                .post('/interfaceApi/production/theoryformula/save/', {
                    theoryFormula: this.theoryFormula,
                    theoryFormulaEntity: this.tableData,
                })
                .then(res => {
                    this.getInitData(res.theoryFormula.theory_formula_number);
                    window.updateTable();
                    this.$message.success('保存成功');
                    this.isLoading = false;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                    this.isLoading = false;
                });
        },
        // 获取原材料名称数据
        getStockCategory() {
            this.$axios
                .get('/interfaceApi/baseinfo/businessgroup_config/getlist_recursion?parent_code=F3-01')
                .then(res => {
                    let stockCategoryData = [];
                    stockCategoryData = res.filter(item => {
                        return item.level === 4;
                    });

                    this.stockCategoryData = stockCategoryData;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 原材料名称选择触发的事件
        stockCategoryChange(val, index) {
            // val 原材料分类code index 当前操作的列的索引
            const row = this.stockCategoryData.filter(item => {
                return item.code === val;
            });

            this.tableData[index].material_code = '';
            this.tableData[index].material_name = '';
            this.$set(this.tableData[index], 'materialData', []);
            if (row.length > 0) {
                this.tableData[index].stock_category_name = row[0].name;
                this.tableData[index].stock_category_dictcode = row[0].dic_code;
                if (row[0].dic_code === '203') { // 细骨料
                    this.getMaterialChild('F3-02', 1, index);
                    this.tableData[index].material_child_code = '';
                    this.tableData[index].material_child_dictcode = '';
                    this.tableData[index].material_child_name = '';
                } else if (row[0].dic_code === '204') { // 粗骨料
                    this.getMaterialChild('F3-03', 1, index);
                    this.tableData[index].material_child_code = '';
                    this.tableData[index].material_child_dictcode = '';
                    this.tableData[index].material_child_name = '';
                } else if (row[0].dic_code.indexOf('-') > -1) {
                    this.$set(this.tableData[index], 'materialChildData', row);
                    this.tableData[index].material_child_code = row[0].code;
                    this.tableData[index].material_child_name = row[0].name;
                    this.tableData[index].material_child_dictcode = row[0].dic_code;
                    this.getMaterial( row[0].dic_code, index);
                } else {
                    this.getMaterialChild(row[0].dic_code, '', index);
                    this.tableData[index].material_child_code = '';
                    this.tableData[index].material_child_dictcode = '';
                    this.tableData[index].material_child_name = '';
                }
            }
        },
        // 根据原材料分类选择的查询原材料子类select
        getMaterialChild(val, type, index) {
            if (type) {
                // 204粗骨料、203细骨料 单独调接口 
                this.$axios
                    .get('/interfaceApi/baseinfo/businessgroup_config/getlist?parent_code=' + val)
                    .then(res => {
                        this.$set(this.tableData[index], 'materialChildData', res);
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                // 其他原材料分类
                this.$axios
                    .get('/interfaceApi/baseinfo/dictionary_config/getlist?parent_code=' + val + '&type=2')
                    .then(res => {
                        this.$set(this.tableData[index], 'materialChildData', res);
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
        // 选择原材料子类触发的事件
        materialChildChange(code1, code2, index) {
            /*
            *code1 原材料分类的code
            *code2 原材料子类的code
            *index 操作列的索引
            */
            const row = this.stockCategoryData.filter(item => {
                return item.code === code1;
            });

            this.tableData[index].material_code = '';
            this.tableData[index].material_name = '';

            if (row.length > 0) {
                const materialChildRow = this.tableData[index].materialChildData.filter(item => {
                    return item.code === code2;
                });

                this.tableData[index].material_child_name = materialChildRow[0].name;
                this.tableData[index].material_child_dictcode = materialChildRow[0].dic_code || '';

                if (row[0].dic_code === '203' || row[0].dic_code === '204') { // 细骨料// 粗骨料
                    this.getMaterial(code2, index, 1);
                } else {
                    this.getMaterial(code2, index);
                }

                //  else if (row[0].dic_code.indexOf('-') > -1) {
                //     this.getMaterial(row[0].dic_code, index);
                // } 
            }

        },
        // 获取原材料规格select数据
        getMaterial(code, index, type) {
            if (type) {
                this.$axios
                    .get('/interfaceApi/baseinfo/businessgroup_config/getlist?parent_code=' + code)
                    .then(res => {
                        this.$set(this.tableData[index], 'materialData', res);
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$axios
                    .get('/interfaceApi/baseinfo/dictionary_config/getlist?parent_code=' + code + '&type=2')
                    .then(res => {
                        this.$set(this.tableData[index], 'materialData', res);
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
        // 原材料规格选择触发的事件
        materialChange(code, index) {
            if (this.tableData[index].stock_category_dictcode === '203' || this.tableData[index].stock_category_dictcode === '204') {
                this.tableData[index].material_name = this.tableData[index].materialData.filter(item => {
                    return item.dic_code === code;
                })[0].name;
            } else {
                this.tableData[index].material_name = this.tableData[index].materialData.filter(item => {
                    return item.code === code;
                })[0].name;
            }
        },
        deleteChange(row, index) {
            // 删除
            if (row.id) {
                this.$axios
                    .delete('/interfaceApi/production/theoryformula/deleteformulaentity/' + row.id)
                    .then(res => {
                        if (res) {
                            this.tableData.splice(index, 1);
                            this.experimentQuantityChange();
                        } else {
                            this.$message.error('删除失败');
                        }
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.tableData.splice(index, 1);
                this.experimentQuantityChange();
            }
        },
        addChange() {
            // 新增
            const sort_num = this.sort_num++;
            this.tableData.push({
                id: null,
                stock_category_dictcode: '',
                stock_category: '',
                stock_category_name: '',
                material_child_dictcode: '',
                material_child_code: '',
                material_child_name: '',
                material_code: '',
                material_name: '',
                theory_quantity: 0,
                moisture_ratio: 0,
                moisture_ratio_difference: 0,
                stone_rate: 0,
                stone_rate_difference: 0,
                // source_goods_code: '',
                // source_goods_name: '',
                sort_num: sort_num,
            });
        },
        // 根据标准用量加和设计容量
        experimentQuantityChange() {
            let total = 0;
            this.tableData.forEach(item => {
                total += Number(item.theory_quantity ? item.theory_quantity : 0);
            });
            this.$set(this.theoryFormula, 'design_volume_weight', total.toFixed(2));
        },
        // 选择配方类型触发的事件
        formulaTypeChange(val) {
            if (val === 1) {
                this.theoryFormula.strength_grade = '';
                // 获取强度等级下拉列表的数据
                this.SelectData('005-SJ0', '0', 'qddj');
            } else {
                this.theoryFormula.strength_grade = '';
                // 获取强度等级下拉列表的数据
                this.SelectData('004-PH0', '0', 'qddj');
            }
        },
        selectAll() {
            // 获取强度等级下拉列表的数据
            this.SelectData('004-PH0', '0', 'qddj');
            // 获取抗渗等级下拉列表的数据
            this.SelectData('501-KS0', '5', 'ksdj');
            // 获取抗冻等级下拉列表的数据
            this.SelectData('501-KD0', '5', 'kddj');
            // 获取抗折等级下拉列表的数据
            this.SelectData('501-KZ0', '5', 'kzyq');
            // 获取特殊要求下拉列表的数据
            this.SelectData('501-TX0', '5', 'tsyq');
        },
        SelectData(code, type, obj) {
            this.$axios
                .get(
                    '/interfaceApi/production/dictionary/dropdownlist/'
                    + code
                    + '/'
                    + type
                )
                .then(res => {
                    if (obj === 'qddj') {
                        this.ProductIdentification.qddj.options = res;
                    } else if (obj === 'ksdj') {
                        this.ProductIdentification.ksdj.options = res;
                    } else if (obj === 'kddj') {
                        this.ProductIdentification.kddj.options = res;
                    } else if (obj === 'kzyq') {
                        this.ProductIdentification.kzyq.options = res;
                    } else if (obj === 'tsyq') {
                        this.ProductIdentification.tsyq.options = res;
                    }
                });
        },
        // 产品标识name赋值
        selectGet(value) {
            const _that = this;
            if (value === 'strength_grade') {
                // 强度等级
                _that.ProductIdentification.qddj.options.forEach(item => {
                    if (item.code === _that.theoryFormula.strength_grade) {
                        _that.theoryFormula.strength_grade_name = item.name;
                    }
                });
            } else if (value === 'impervious') {
                // 抗渗等级
                _that.ProductIdentification.ksdj.options.forEach(item => {
                    if (item.code === _that.theoryFormula.impervious) {
                        _that.theoryFormula.impervious_name = item.name;
                    }
                });
            } else if (value === 'antifreeze') {
                // 抗冻等级
                _that.ProductIdentification.kddj.options.forEach(item => {
                    if (item.code === _that.theoryFormula.antifreeze) {
                        _that.theoryFormula.antifreeze_name = item.name;
                    }
                });
            } else if (value === 'flexural') {
                // 抗折要求
                _that.ProductIdentification.kzyq.options.forEach(item => {
                    if (item.code === _that.theoryFormula.flexural) {
                        _that.theoryFormula.flexural_name = item.name;
                    }
                });
            } else if (value === 'special_require') {
                // 特殊要求
                _that.ProductIdentification.tsyq.options.forEach(item => {
                    if (item.code === _that.theoryFormula.special_require) {
                        _that.theoryFormula.special_require_name = item.name;
                    }
                });
            }
        },
    },
};
</script>

<style lang="stylus">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}
.experiment_matching {
    width: 100%;
    background: #e0e5eb;
    height: 100%;
    display: flex;
    flex-direction:column;
    .el-dialog{
        height: 60% !important;
        .el-table__row{
            td{
                padding: 0;
            }
            height 0.34rem;
        }
        .el-button{
            margin: 0 0.4rem;
        }
    }
    .el-dialog__wrapper{
        height: 100% !important;
    }
  .header_wrapper {
    width: 100%;
    min-height: 0.6rem;
    background: #ffffff;
    padding: 0.1rem;
     overflow:hidden;
    .header_left {
      float: left;

      .input_item {
        float: left;
        margin-right: 0.2rem;

        span {
          font-size: 0.16rem;
        }

        .el-input {
          width: 2.4rem;

          .el-input__inner {
            height: 0.36rem;
            line-height: 0.36rem;
          }
        }
      }
    }

    .header_right {
      float: right;

      .el-button {
        width: 0.6rem;
        height: 0.34rem;
        padding: 0;
        font-size: 0.16rem;
        background: #1577d2;
      }
    }
  }

  .main_wrapper {
    display: flex;
    flex-direction: column;
    flex:1
    .main_top {
      height: 3.4rem;
      margin: 0.2rem 0;
      display: flex;

      .product_marking_wrapper {
        width: 4rem;
        height: 100%;
        background: #ffffff;
        padding: 0.1rem 0.2rem;

        p {
          font-size: 0.18rem;
          border-left: 0.04rem solid #1577D2;
          padding-left: 0.1rem;
          font-weight: 800;
        }

        ul {
          li {
            line-height: 0.5rem;

            span {
              font-size: 0.16rem;
            }

            .el-select {
              width: 2.4rem;
            }
          }
        }
      }

      .basic_info_wrapper {
        // width: 9rem;
        height: 100%;
        background: #ffffff;
        margin-left: 0.2rem;
        padding: 0.1rem 0.2rem;
        flex: 1;
        p {
          font-size: 0.18rem;
          border-left: 0.04rem solid #1577D2;
          padding-left: 0.1rem;
          font-weight: 800;
        }

        ul {
          li {
            line-height: 0.5rem;

            .item_title {
              font-size: 0.16rem;
              width: 1.3rem;
              display: inline-block;
            }

            .el-select {
              width: 2.5rem;
            }

            .el-input {
              width: 2.5rem;
            }
          }
        }
      }

      .matching_situation_wrapper {
        width: 3.8rem;
        height: 100%;
        background: #ffffff;
        padding: 0.1rem 0.2rem;

        p {
          font-size: 0.18rem;
          border-left: 0.04rem solid #1577D2;
          padding-left: 0.1rem;
          font-weight: 800;
        }

        .el-table {
          font-size: 0.16rem;
          width: 2.6rem;
          margin-top: 0.1rem;

          .el-table__header-wrapper {
            .el-table__header {
              width: 100% !important;
              table-layout: auto;

              .cell {
                padding: 0;
                text-align: center;
              }
            }
          }

          .el-table__body-wrapper {
            .el-table__body {
              width: 100% !important;
              table-layout: auto;

              .el-table__row {
                td {
                  height: 0.34rem;
                  padding: 0;

                  .cell {
                    padding: 0;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }

    .main_bottom {
        flex: 1;
        width: 100%;
        background: #ffffff;
        padding: 0.2rem 0.2rem 0 0.2rem;
        display: flex;
    flex-direction: column;
      .main_header {
        // display: flex;
        width: 12.4rem;
        .production_wrapper {
            width: 8rem;
            float: left;
          .production_span {
            font-size: 0.16rem;
            float: left;
            line-height: 0.34rem;
          }

          .radio_list {

            .el-radio {
              // width 0.6rem
              height: 0.34rem;
              // padding 0.1rem
              //margin-right: 0.1rem;
              margin-right: 0.1rem !important;
              line-height 0.1rem;
              .el-radio__input{
                display none
              }
              .el-radio__label{
                  padding 0;
                  width:100%;
                  height:100%;
                  line-height 0.1rem;
              }
            }

            .is-bordered {
              padding: 0.1rem;
            }
          }
        }

        .btn_wrapper {
          margin-left: 0.4rem;
            float:right;
          .el-button {
            // width 0.34rem
            height: 0.34rem;
            padding: 0.1rem;
            font-size: 0.16rem;
            background #1577d2;
          }
        }
      }

      .main_content {
        margin-top: 0.2rem;
        display: flex;
        flex:1
        .content_left {
          width: 12.4rem;
        //   .el-input__inner{
        //       padding-right 0
        //   }
          .el-table {
            // width 12.4rem
            font-size: 0.16rem;
            height calc(100% - 0.2rem) !important;
            .el-table__header-wrapper {
            //   width: 12.5rem !important;
            }

            .el-table__header {
              width: 12.5rem !important;
              table-layout: inherit;

              .cell {
                padding: 0;
                text-align: center;
              }
            }

            .el-table__body-wrapper {
            //   width: 12.5rem !important;

              .el-table__body {
                width: 12.5rem !important;
                table-layout: inherit;

                .el-table__row {
                  td {
                    height: 0.34rem;
                    padding: 0;

                    .cell {
                      padding: 0;
                      text-align: center;
                      // .el-input__inner
                      // border 1px solid transparent
                    }
                  }
                }
              }
            }
            .el-table__empty-block{
                width: 12.5rem !important;
            }
          }
        }

        .content_rigth {
          margin-left: 0.2rem;

          ul {
            li {
              width: 3rem;
              height: 0.5rem;
              border: 1px solid rgba(215, 215, 215, 1);
              margin-bottom: 0.2rem;

              span {
                font-size: 0.18rem;
                line-height: 0.5rem !important;
                display: block;
                height: 0.5rem;
                width: 1rem;
                float: left;
              }
              p {
                float: right;
                line-height: 0.5rem !important;
                font-size: 0.32rem;
                margin-right: 0.1rem;

                i {
                  font-size: 0.16rem;
                  color: rgba(215, 215, 215, 1);
                }
              }
            }

            .sp1 {
              span {
                background: #5588f12e;
                color: #5588F1;
              }

              p {
                color: #5588F1;
              }
            }

            .sp2 {
              span {
                background: #eb656f7a;
                color: #EB656F;
              }

              p {
                color: #EB656F;
              }
            }

            .sp3 {
              span {
                background: #21bdcc75;
                color: #21BDCC;
              }

              p {
                color: #21BDCC;
              }
            }

            .sp4 {
                 margin-bottom: 0;
              span {
                background: #fa843657;
                color: #FA8436;
              }

              p {
                color: #FA8436;
              }
            }
          }
        }
      }
    }
  }
}
</style>